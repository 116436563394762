import AbstractComponent from '../abstract-component';
import * as styles from './messageDate.css'

export class MessageDateComponent extends AbstractComponent {
  constructor() {
    super()
  }

  getTemplate() {
    return `<div class="${styles['item-box']}">
        <div class="${styles['item']}">
          <p class="${styles['item-text']}">СЕГОДНЯ</p>
        </div>
      </div>`
  }
}
