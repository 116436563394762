// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#root__pfdwt-widget--PC7AS4 .callbackForm__container--1_tWn{background:#fff;border-radius:8px;box-shadow:0 0 3px rgb(0 0 0/20%)!important;left:-380px;margin-bottom:20px;min-height:100%;padding:20px 25px;position:relative;transition:left .2s ease-out}#root__pfdwt-widget--PC7AS4 .callbackForm__form--dOMJn{opacity:1}#root__pfdwt-widget--PC7AS4 .callbackForm__item--1CItD{display:block;margin-bottom:20px;position:relative;width:100%}#root__pfdwt-widget--PC7AS4 .callbackForm__item--1CItD input{border:1px solid #e5e5e8;border-radius:6px;box-sizing:border-box;color:#2f2e39!important;display:block;font-size:16px!important;height:49px;line-height:22px;padding:0 33px 0 16px;width:100%}#root__pfdwt-widget--PC7AS4 .callbackForm__item--1CItD input::placeholder{color:#2f2e39!important}#root__pfdwt-widget--PC7AS4 .callbackForm__item--1CItD input:focus{outline:none}#root__pfdwt-widget--PC7AS4 .callbackForm__button--UHNzg button{background:darkred;border:none;border-radius:8px;box-shadow:none;color:#fff;cursor:pointer;display:block;font-size:16px!important;line-height:20px!important;padding:14px 10px;position:relative;text-align:center;transition:background-color .2s;width:100%}#root__pfdwt-widget--PC7AS4 .callbackForm__button--UHNzg button:focus{outline:none}#root__pfdwt-widget--PC7AS4 .callbackForm__button--UHNzg button:hover{background-color:red}#root__pfdwt-widget--PC7AS4 .callbackForm__no-validate--2PGKj{border-color:darkred!important}#root__pfdwt-widget--PC7AS4 .callbackForm__succes-status--1Zc4b{background-color:#fff!important;border:2px solid#67cc43!important;color:#67cc43!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root__pfdwt-widget--PC7AS4": "root__pfdwt-widget--PC7AS4",
	"container": "callbackForm__container--1_tWn",
	"form": "callbackForm__form--dOMJn",
	"item": "callbackForm__item--1CItD",
	"button": "callbackForm__button--UHNzg",
	"no-validate": "callbackForm__no-validate--2PGKj",
	"succes-status": "callbackForm__succes-status--1Zc4b"
};
export default ___CSS_LOADER_EXPORT___;
