import AbstractComponent from '../abstract-component';
import * as styles from './callbackForm.css'

export class CallbackFormComponent extends AbstractComponent {

  sendData
  sendButtonClickHandler: () => void

  constructor(sendData) {
    super()
    this.sendData = sendData
  }

  getTemplate() {
    return `<div class="${styles['container']}">
        <div class="${styles['form']}">
          <div class="${styles['item']}">
            <input data-form-field="name" type="text" maxlength="40" placeholder="Ваше имя"></input>
          </div>
          <div class="${styles['item']}">
            <input class="${styles['item']}-phone" data-form-field="phone" data-numberphone="" type="text" maxlength="40" placeholder="Ваш телефон" autofocus></input>
          </div>
          <div class="${styles['button']}">
            <button type="submit">Отправить</button>
          </div>
        </div>
      </div> `
  }
  
  initSubComponents() {
    const el = super.getElement()
    const phoneInput = el.getElementsByClassName(`${styles['item']}-phone`)[0]
    this.onPhoneMask(phoneInput)
  }

  addEventListeners() {
    const el = super.getElement()
    const sendButton = el.getElementsByClassName(`${styles['button']}`)[0].firstElementChild
    sendButton.addEventListener('click', () => {
      if (this.validateForm()) {
        this.sendButtonClickHandler()
      }
    })

    const inputs = el.querySelectorAll('[data-form-field]')
    for (let input of inputs) {
      input.addEventListener('input', () => {
        if (input.classList.contains(`${styles['no-validate']}`)) {
          input.classList.remove(`${styles['no-validate']}`)
        }
      })
    }
  }

  addSendButtonClickHandler(callback: () => void) {
    this.sendButtonClickHandler = callback
  }

  disableSendButton() {
    const el = super.getElement()
    const sendButton = el.getElementsByClassName(`${styles['button']}`)[0].firstElementChild
    sendButton['disabled'] = true
  }

  enableSendButton() {
    const el = super.getElement()
    const sendButton = el.getElementsByClassName(`${styles['button']}`)[0].firstElementChild
    sendButton['disabled'] = false
  }

  setSuccessStatus() {
    const el = super.getElement()
    const sendButton = el.getElementsByClassName(`${styles['button']}`)[0].firstElementChild
    sendButton.innerHTML = 'Успешно отправлено'
    sendButton.classList.add(`${styles['succes-status']}`)
  }

  show() {
    const el = super.getElement()
    el.setAttribute('style', 'left:0')
  }

  private validateForm() {
    let inputChecker = true
    const el = super.getElement()
    const inputs = el.querySelectorAll('[data-form-field]')

    for (let input of inputs) {

      if (input['dataset'].formField === 'phone') {
        if (input['dataset'].numberphone.length < 10) {
          input.classList.add(`${styles['no-validate']}`)
          inputChecker = false
        } else {
          this.sendData['phone'] = input['value']
        }
      }

      if (input['value'] === '') {
        input.classList.add(`${styles['no-validate']}`)
        inputChecker = false
      } else {
        this.sendData[input['dataset'].formField] = input['value']
      }

    }

    return inputChecker
  }

  private onPhoneMask(selector : Element) {
    let onPhoneMaskSet = []

    if (onPhoneMaskSet) if (~onPhoneMaskSet.indexOf(selector)) return;
    else onPhoneMaskSet.push(selector);
    else onPhoneMaskSet = [selector];

    function phone2int(str: string) {
        if (!str)
            return '';
        var i : number = 0, res : string = '', s : string, ln : number;
        for (ln = str.length; i < ln; i++) s = str[i], res += s >= '0' && s <= '9' ? s : '';

        if (+res[0] === 7) res = res.substring(1);
        if (+res[0] !== 9) return '9';
        return res.substr(0, 10);
    }

    function int2phone(str: string, res: string = '') {
        str = str || '';
        res = '+7  ( ';
        for (var i = 0, ln = str.length, s : string; i < 10; i++) {
            s = i < ln ? str[i] : !i ? '9' : '  ';
            if (~[0, 1, 2, 4, 5, 7, 9].indexOf(i)) res += s;
            else switch (i) {
                case 3:
                    res += ' ) ' + s;
                    break;
                case 6:
                case 8:
                    res += ' - ' + s;
                    break
            }
        }
        return res;
    }

    function setCaret(item, pos) {
        if (!item.value.length)
            return;
        var xlat = {
            0: 7,
            1: 7,
            2: 8,
            3: 12,
            4: 13,
            5: 14,
            6: 17,
            7: 19,
            8: 22,
            9: 24
        }[pos];
        pos = xlat === undefined ? item.value.length : xlat;

        if (item.setSelectionRange)
            item.focus(),
                item.setSelectionRange(pos, pos + 2);
        else if (typeof item.createTextRange !== 'undefined') {
            var range = item.createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos + 2);
            range.moveStart('character', pos);
            range.select();
        }
    }

    function localEventMaskPhone(e) {
        var targ = e.target, i, n, arr = [];
        for (i = 0, n = onPhoneMaskSet.length; i < n; i++) arr.push(onPhoneMaskSet[i]);
        if (~arr.indexOf(targ)) {
            targ.hasAttribute('data-numberphone') || targ.setAttribute('data-numberphone', '');
            targ.hasAttribute('data-rawphone') || targ.setAttribute('data-rawphone', '');

            var hidInpt = targ.getAttribute('data-numberphone'),
                rawInput = targ.value,
                showinput = phone2int(rawInput);

            if (e.type === 'focus' || e.type === 'focusin') {
                if (hidInpt === '')
                    rawInput = int2phone(''), targ.value = rawInput;

                setCaret(targ, showinput.length);
                targ.setAttribute('data-rawphone', rawInput);
                targ.setAttribute('data-numberphone', hidInpt);
                return;
            }
            if (e.type === 'blur' || e.type === 'focusout') {
                targ.value = int2phone(hidInpt);
                targ.setAttribute('data-rawphone', rawInput);
                targ.setAttribute('data-numberphone', hidInpt);
                return;
            }
            if (e.type === 'keydown' && targ.tagName === 'INPUT' && e.keyCode === 8) {//backspace
                hidInpt = hidInpt ? hidInpt.substr(0, hidInpt.length - 1) : '',
                    rawInput = int2phone(hidInpt), targ.value = rawInput;
                setCaret(targ, rawInput.length);
                targ.setAttribute('data-rawphone', rawInput);
                targ.setAttribute('data-numberphone', hidInpt);
                return;
            }
            if (e.type === 'input') {
                if (targ.getAttribute('data-rawphone') !== rawInput)
                    hidInpt = phone2int(rawInput),
                        rawInput = int2phone(hidInpt), targ.value = rawInput;

                setCaret(targ, showinput.length);
                targ.setAttribute('data-rawphone', rawInput);
                targ.setAttribute('data-numberphone', hidInpt);

            }
        }
    }

    selector.addEventListener('focus', localEventMaskPhone, true);
    selector.addEventListener('blur', localEventMaskPhone, true);
    selector.addEventListener('input', localEventMaskPhone, false);
    selector.addEventListener('keydown', localEventMaskPhone, false);

  }
}
