// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal__container--2mrDp{position:relative}#root__pfdwt-widget--PC7AS4 .modal__modal__scroll-box--3yQwA{align-items:flex-start;background-position:50%;background-repeat:no-repeat;border-radius:8px;box-shadow:0 0 10px 0 rgb(0 0 0/12%)!important;box-sizing:border-box;display:flex;flex-direction:column;height:auto;margin-right:0;opacity:1;overflow:hidden;overflow-anchor:none;padding:0;z-index:999999}#root__pfdwt-widget--PC7AS4 .modal__modal__modal-box--rmZNg{box-sizing:border-box;overflow-x:unset;padding:0;width:100%}#root__pfdwt-widget--PC7AS4 .modal__modal__header-box--1aob2{opacity:1}#root__pfdwt-widget--PC7AS4 .modal__modal__message-box-scroll--1ciNg{background:#fff4ec;display:flex;flex-direction:column;max-height:400px;min-height:400px;overflow-y:auto;transition:max-height .4s}#root__pfdwt-widget--PC7AS4 .modal__modal__message-box-scroll--1ciNg:last-of-type{min-height:517px!important}#root__pfdwt-widget--PC7AS4 .modal__modal__message-box--3kTX1{align-items:flex-end;background:#fff4ec;box-sizing:border-box;display:flex;padding:28px;width:100%}#root__pfdwt-widget--PC7AS4 .modal__modal__message-list--2xEYW{display:flex;flex-direction:column;position:relative;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "modal__container--2mrDp",
	"root__pfdwt-widget--PC7AS4": "root__pfdwt-widget--PC7AS4",
	"modal__scroll-box": "modal__modal__scroll-box--3yQwA",
	"modal__modal-box": "modal__modal__modal-box--rmZNg",
	"modal__header-box": "modal__modal__header-box--1aob2",
	"modal__message-box-scroll": "modal__modal__message-box-scroll--1ciNg",
	"modal__message-box": "modal__modal__message-box--3kTX1",
	"modal__message-list": "modal__modal__message-list--2xEYW"
};
export default ___CSS_LOADER_EXPORT___;
