// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#root__pfdwt-widget--PC7AS4 .textArea__container--JQKCN{background:#fff;border-radius:0;border-top:1px solid rgba(193,192,196,.5);box-shadow:0 -20px 20px 4px hsla(0,0%,67%,.145)!important;box-sizing:border-box;display:flex;flex-direction:column;margin-top:0;padding:25px;transition:bottom .3s ease-out;width:100%}#root__pfdwt-widget--PC7AS4 .textArea__box--1s-J2{display:flex}#root__pfdwt-widget--PC7AS4 .textArea__send-message--1SuWt{align-items:center;display:flex;width:100%}#root__pfdwt-widget--PC7AS4 .textArea__send-message--1SuWt textarea{border:none;box-sizing:border-box;color:#2f2e39;font-family:Arial,Helvetica,sans-serif;font-size:16px!important;letter-spacing:-.35px;line-height:22px;max-height:140px;padding-right:15px;resize:none;width:100%}#root__pfdwt-widget--PC7AS4 .textArea__send-message--1SuWt textarea:focus{outline:none}#root__pfdwt-widget--PC7AS4 .textArea__send-button--2vRq9{align-items:center;align-self:center;border:none;border-radius:50%;display:flex;height:37px;justify-content:center;padding:10px 0;width:40px}#root__pfdwt-widget--PC7AS4 .textArea__send-button--2vRq9 svg{padding-left:3px}#root__pfdwt-widget--PC7AS4 .textArea__send-button--2vRq9:hover{cursor:pointer}#root__pfdwt-widget--PC7AS4 .textArea__send-button--2vRq9:focus{outline:none}#root__pfdwt-widget--PC7AS4 .textArea__error-message--2iLXq{color:darkred;font-size:16px!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root__pfdwt-widget--PC7AS4": "root__pfdwt-widget--PC7AS4",
	"container": "textArea__container--JQKCN",
	"box": "textArea__box--1s-J2",
	"send-message": "textArea__send-message--1SuWt",
	"send-button": "textArea__send-button--2vRq9",
	"error-message": "textArea__error-message--2iLXq"
};
export default ___CSS_LOADER_EXPORT___;
