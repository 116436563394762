import { createElement } from "../utils";

/**
 * @file Абстрактный компонент
 * @author Fedunin A.A.
 */

const HIDDEN_CLASS = `visually-hidden`;

export default class AbstractComponent {
  private element: Element

  /**
   * 
   * Метод получения строки разметки
   * 
   * @return string
   */
  getTemplate(): string {
    throw new Error(`Abstract method not implemented: getTemplate`)
  }

  /**
   * 
   * Получение (и создание в случае отсутствия) элемента. Если компонент создается впервые также инициализирует подкомпоненты и добавляет слушателей событий
   * 
   * @return element
   */
  getElement() {
    if (!this.element) {
      this.element = createElement(this.getTemplate())
      this.initSubComponents()
      this.addEventListeners()
    }

    return this.element
  }

  /**
   * Добавление слушателей событий компонента
   */
  addEventListeners() { }

  /**
   * Инициализация подкомпонентов
   */
  initSubComponents() { }

  /**
   * Удаление слушателей событий компонента
   */
  deleteEventListeners() { }

  /**
   * Удаление подкомпонентов
   */
  deleteSubComponents() {}

   /**
   * Удаление элемента
   */
  removeElement() {
    this.deleteSubComponents()
    this.deleteEventListeners()
    this.element && this.element.remove()
    this.element = null
  }

  /**
   * Показ элемента
   */
  show() {
    if (this.element) {
      this.element.classList.remove(HIDDEN_CLASS)
    }
  }

  /**
   * Скрытие элемента
   */
  hide() {
    if (this.element) {
      this.element.classList.add(HIDDEN_CLASS)
    }
  }
}
