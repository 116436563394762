import { Params } from '../../widget/PopUpProfWidget.model';
import AbstractComponent from '../abstract-component';
import * as styles from './modal.css'

export class ModalComponent extends AbstractComponent {
  initParams: Params

  constructor(initParams: Params) {
    super()
    this.initParams = initParams
  }

  getTemplate() {
    return `<div class="${styles['container']}">
        <div class="${styles['modal__scroll-box']}">
          <div class="${styles['modal__modal-box']}">
            <div class="${styles['modal__header-box']}">
            </div>
            <div class="${styles['modal__message-box-scroll']}">
              <div class="${styles['modal__message-box']}">
                <div class="${styles['modal__message-list']}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>`
  }
  getMessageListElement() {
    const el = this.getElement()
    return el.getElementsByClassName(styles['modal__message-list'])[0]
  }

  getModalBoxElement() {
    const el = this.getElement()
    return el.getElementsByClassName(styles['modal__modal-box'])[0]
  }

  getHeaderBoxElement() {
    const el = this.getElement()
    return el.getElementsByClassName(styles['modal__header-box'])[0]
  }
}

