import { generateNewColor } from '../../utils';
import { Params } from '../../widget/PopUpProfWidget.model';
import AbstractComponent from '../abstract-component';
import { Avatar } from '../avatar/avatar';
import * as styles from './header.css'

export class HeaderComponent extends AbstractComponent {

  name: string
  position: string
  params: Params
  avatar: Avatar
  closeButtonClickHandler: () => void

  constructor(initParams: Params) {
    super()
    this.name = initParams.params.fio
    this.position = initParams.params.metier
    this.params = initParams
  }

  getTemplate() {
    return `<div class="${styles['header']}" style="background: linear-gradient(90deg, ${this.params.params.widget_color} 0%,
      ${generateNewColor(this.params.params.widget_color, 0.12, true)} 100%);">
        <div class="${styles['consultant']}">
          <div class="${styles['avatar']}">
            <div class="${styles['avatar-box']}" style="background-image:url(${this.params.params.video.photo});background-size:cover;">
            </div>
            <div class="${styles['status']}">
              <div class="${styles['circle']}"></div>
            </div>
          </div>
          <div class="${styles['right-part']}">
            <p>${this.name}</p>
            <span>${this.position}</span>
          </div>
        </div>
      </div>`
  }

  getAvatarBoxElement() {
    const el = this.getElement()
    return el.getElementsByClassName(`${styles['avatar-box']}`)[0]
  }
}
