/**
 * @file Модуль для создания аватара.
 * @author Fedunin A.A.
 */

export const ANIMATION_TYPE = {
  initial: 'initial',
}

/**
* Функция декоратор
* 
* @param {object} params - параметры указанные при инициализации виджета
* @returns {VideoAvatarCreator|SimpleAvatarCreator}
*/
export function initAvatarCreator(params) {
  let video = params['video'];
  switch (params['isVideo']) {
      case true:
          return new VideoAvatarCreator([video['videoEvents'], video['videoChunks'], video['photo']]);
      default:
          return new SimpleAvatarCreator(params['photo']);
  }
}

class AvatarCreator {
  source

  constructor(source) {
    this.source = source;
  }

  baseCreateAvatar() {
    throw new Error('Создание виртуального класса Avatar');
  }
}

class SimpleAvatarCreator extends AvatarCreator {
  /**
   * @return SimpleAvatar
   */
  baseCreateAvatar() {
    return new SimpleAvatar(this.source);
  }
}

class VideoAvatarCreator extends AvatarCreator {
  /**
   * @return VideoAvatar
   */
  baseCreateAvatar() {
    return new VideoAvatar(this.source);
  }
}

export class Avatar {
  item

  constructor () {}

  render() {}

  relocationDOM(parentNode) {
    parentNode.appendChild(this.item);
    return this;
  }
}

class SimpleAvatar extends Avatar {
  /**
   * Класс аватара-картинки
   * 
   * @param {string} source - image source
   * 
   */

  source
  type

  constructor(source) {
    super();
    this.source = source;
    this.item = null;
    this.type = 'smple_avatar'
  }

  render() {
    this.item = document.createElement('img');
    this.item.src = this.source;
    return this;
  }
}

class VideoAvatar extends Avatar {
  /**
   * Класс видео-аватара
   * 
   * @param {array} source - массив видеонаборов: [video Events - [видео инициализации, видео при получении лида], video Chunks, reserve image - передаем картинку на случай ошибки по видео]
   * 
   */

  source
  Initials
  Chunks
  shuffledChunks
  initialEvent
  linkEvent
  notPlaying
  type
  Queue

  constructor(source) {
    super();
    this.source = source;
    this.Initials = this.source[0];
    this.Chunks = this.source[1];
    this.shuffledChunks = this._shuffle(this.Chunks.slice(0));
    this.initialEvent = this.Initials['initial'];
    this.linkEvent = this.Initials['lead'];

    this.notPlaying = false;

    this.item = null;
    this.type = 'advanced_video';
    this.Queue = {
        current: null,
        next: null
    };
  }

  render() {
    this.item = document.createElement('div');
    this.item.classList.add('animation-video');

    this.Queue.current = this._createElement(this.initialEvent);
    this.Queue.current.setAttribute('style', 'display: block; z-index: 1; opacity: 1');
    this.Queue.current.setAttribute('type', 'video/mp4');
    this.Queue.current.play();
    this.item.appendChild(this.Queue.current);

    return this;
  }

  _createElement(source) {
    const elementItem = document.createElement('video');
    const sourceItem = document.createElement('source');
    sourceItem.src = source;
    sourceItem.setAttribute('type', 'video/mp4');

    elementItem.appendChild(sourceItem);
    elementItem.setAttribute('style', 'position: absolute; top: 0; display: none; opacity: 0; z-index: 0');
    elementItem.setAttribute('playsinline', 'playsinline');
    elementItem.setAttribute('constrols', 'false');
    elementItem.setAttribute('muted', 'muted');
    elementItem.muted = true;
    elementItem.setAttribute('preload', 'metadata');

    elementItem.addEventListener('ended', () => {
        this._transition();
    });

    return elementItem;
  }

  _transition() {
    if (this.shuffledChunks.length === 0) this.shuffledChunks = this._shuffle(this.Chunks.slice(0));

    this.Queue.next = this.Queue.next ? this.Queue.next : this._createElement(this.shuffledChunks.shift());
    this.item.appendChild(this.Queue.next);
    setTimeout(() => {
      this._fade(this.Queue.current, this.Queue.next, 30)
      this.Queue.current = this.Queue.next;  
      this.Queue.next = null;
    }, 300)
  }

  _shuffle(array) {
    return array.sort(function(){
        return Math.random() - 0.5;
    });
  }

  _fade(prevElement, nextElement, speed){
    nextElement.style.display = 'block';
    nextElement.style.zIndex = 1;
    nextElement.play();
    if (nextElement.style.opacity != 1){
        prevElement.style.zIndex = 0;
        var speed = speed || 30 ;
        var num = 0;
        var st = setInterval(function(){
            num++;
            nextElement.style.opacity = num / 10;
            if (num >= 10) {
                clearInterval(st);
                prevElement.remove();
            };
        }, speed);
    }
  }

  animationControl(type) {
    switch (type) {
      case ANIMATION_TYPE.initial:
        if (!this.notPlaying) {
            this.Queue.next = this._createElement(this.linkEvent);
        };
        break;
    }

    return this;
  }
}
