// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#root__pfdwt-widget--PC7AS4 .header__header--35ndk{align-items:center;background:linear-gradient(90deg,#2682df,#0657a7);border-radius:8px 8px 0 0;box-shadow:0 -2px 16px rgb(0 0 0/26%)!important;box-sizing:border-box;display:flex;height:80px;padding:0 28px;position:relative;top:0;width:100%}#root__pfdwt-widget--PC7AS4 .header__consultant--1CoV7{align-items:center;display:flex}#root__pfdwt-widget--PC7AS4 .header__close-button--2cRmu{align-items:center;background:transparent;border-radius:50%;cursor:pointer;display:flex;height:31px;justify-content:center;opacity:.7;position:absolute;right:15px;right:20px;top:10px;transform:translateZ(0);transition:opacity .25s ease-in-out,visibility .25s;visibility:visible;width:31px;will-change:auto;z-index:2147483001}#root__pfdwt-widget--PC7AS4 .header__close-button--2cRmu:hover{opacity:1}#root__pfdwt-widget--PC7AS4 .header__avatar--2y_ul{display:flex;height:50px;margin-right:15px;position:relative;width:50px}#root__pfdwt-widget--PC7AS4 .header__avatar-box--Hhz0t{background:none;border-radius:50%;height:48px;overflow:hidden;position:relative;width:48px;z-index:1}#root__pfdwt-widget--PC7AS4 .header__status--3tK_A{bottom:3px;position:absolute;right:1px;z-index:10}#root__pfdwt-widget--PC7AS4 .header__circle--2BLH1{background-color:#92c97a;border:2px solid #fff;border-radius:50%;box-sizing:border-box;height:10px;margin-left:10px;width:10px}#root__pfdwt-widget--PC7AS4 .header__right-part--DPROo{color:#fff;display:flex;flex-direction:column}#root__pfdwt-widget--PC7AS4 .header__right-part--DPROo p{align-items:center;color:#fff!important;display:flex;font-size:18px!important;font-weight:400!important;margin:0 0 5px}#root__pfdwt-widget--PC7AS4 .header__right-part--DPROo span{font-size:13px!important;font-weight:400!important}@media screen and (max-width:500px){#root__pfdwt-widget--PC7AS4 .header__header--35ndk{border-radius:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root__pfdwt-widget--PC7AS4": "root__pfdwt-widget--PC7AS4",
	"header": "header__header--35ndk",
	"consultant": "header__consultant--1CoV7",
	"close-button": "header__close-button--2cRmu",
	"avatar": "header__avatar--2y_ul",
	"avatar-box": "header__avatar-box--Hhz0t",
	"status": "header__status--3tK_A",
	"circle": "header__circle--2BLH1",
	"right-part": "header__right-part--DPROo"
};
export default ___CSS_LOADER_EXPORT___;
