// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overlay__overlay--3G-ts{align-items:center;background-color:#000;color:#fff;display:flex;font-size:20px!important;height:100%;justify-content:center;left:0;opacity:.6;position:absolute;text-align:center;top:0;width:100%;z-index:9999999}.overlay__text--1-0jF{opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "overlay__overlay--3G-ts",
	"text": "overlay__text--1-0jF"
};
export default ___CSS_LOADER_EXPORT___;
