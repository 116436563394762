import { config } from './config'
import chunk_1 from './static/videos/video_2/chunk-1.mp4'
import chunk_2 from './static/videos/video_2/chunk-2.mp4'
import chunk_3 from './static/videos/video_2/chunk-3.mp4'
import chunk_4 from './static/videos/video_2/chunk-4.mp4'
import initial from './static/videos/video_2/initial.mp4'
import lead from './static/videos/video_2/lead.mp4'
import plug from './static/videos/video_2/plug.png'

import PopUpProfWidget from './widget/PopUpProfWidget'

window['chatWidget'] = PopUpProfWidget;

window['profWidget'] = new PopUpProfWidget();

!config.prodMode && window['profWidget'].init({
  txts: {
    secondMsg: 'Напишите, что вас интересует, и я вам обязательно помогу!',
    firstMsg: 'Здравствуйте! У вас есть вопрос или вам нужна помощь юриста?<br/>Мы консультируем бесплатно!',
    topNotify: 'Если возникнут вопросы, или нужна будет\n помощь, обращайтесь, я вам помогу',
    callbackMatier: 'Закажите звонок!',
    callbackFio: 'Мы перезвоним в удобное время.',
    msgAnsByPhone: 'Вы можете оставить свои контакты и мы свяжемся с Вами для дальнейшего плодотворного сотрудничества',
    netErr: 'Сетевая ошибка. Повторите.',
  },
  params: {
    container_id: '#testId',
    fio: 'Елена Владимировна',
    metier: 'Юрист - консультант',
    widget_color: '#d19130',
    timeout4Start: 1000,
    timeout4LawyerAnimate: 16000,
    isVideo: true,
    video: {
        videoEvents: { initial: initial, lead: lead },
        videoChunks: [chunk_1, chunk_2, chunk_3, chunk_4],
        photo:  plug,
    },
    exitTrap: true,
    left: 0,
    token: 'N3zEaQIzxyzG4N7L6p7Gk_tTKOjeD4YQ',
    mobileLeft: 0,
    script: 'main.js',
    soundBadge: true,
    photo: '',
    disableWidgetsCallback: (id) => {console.log(id)}
  }
})
