import { config } from "../config"
import { Params, Txts, Prms } from "./PopUpProfWidget.model"
import { RenderPosition, MessageSender, render} from "../utils"

import { RootComponent } from "../components/root/root"
import { ModalComponent } from "../components/modal/modal"
import { MessageComponent } from "../components/message/message"
import { TextAreaComponent } from "../components/textArea/textArea"
import { PrintingComponent } from "../components/printing/printing"
import { CallbackFormComponent } from "../components/callbackForm/callbackForm"
import { HeaderComponent } from "../components/header/header"
import { initAvatarCreator, Avatar } from "../components/avatar/avatar"
import { MessageDateComponent } from "../components/messageDate/messageDate"
import { OverlayComponent } from "../components/overlay/overlay"

const LOGIC_CHAIN = ['welcomeLink', 'questionLink', 'sendLink'];

/**
 * Main class of widget
 * ?? Service Locator
 */
 export class PopUpProfWidget {
  initParams: Params
  params: Prms
  texts: Txts

  isProxy: Boolean
  isFirstModalOpen: Boolean = true
  isModalOpen: Boolean = false
  notifyInterval: ReturnType<typeof setTimeout>
  beforeAnimateTimeout: ReturnType<typeof setTimeout>
  badgeTimeout: ReturnType<typeof setTimeout>

  avatarComponent: Avatar
  rootComponent: RootComponent
  overlayComponent: OverlayComponent
  modalComponent: ModalComponent
  textAreaComponent: TextAreaComponent
  printingComponent: PrintingComponent
  headerComponent: HeaderComponent
  callbackformComponent: CallbackFormComponent

  messageListComponent: Element
  modalBoxComponent: Element
  headerBoxComponent: Element
  headerAvatarBoxComponent: Element
  notifyAvatarBoxComponent: Element

  sendData = {} as { name: string, phone: string }

  constructor( ) { }

  async init(initParams: Params) {
    this.initParams = initParams
    this.params = initParams.params
    this.texts = initParams.txts

    this.startWidget();
  }

  // ==== init sheet ====
  private startWidget() {
    this.initBaseComponents()// создаем объекты в памяти
    this.addComponentsHandlers() // обавляем Event Listener
    this.renderBaseComponents() // render

    if (sessionStorage.getItem('activeWidget') && this.params.container_id !== sessionStorage.getItem('activeWidget')){
      this.restoreWelcomeLogic();
      this.disableCurrentWidget();
      return;
    }

    if (!sessionStorage.getItem(LOGIC_CHAIN[0])) {
      this.startModalLogic();
      return;
    }

    if (sessionStorage.getItem(LOGIC_CHAIN[0])) {
      this.restoreWelcomeLogic();
    }

    if (sessionStorage.getItem('question')) {
      this.textAreaComponent.hide()
      setTimeout(() => this.textAreaComponent.removeElement(), 0)
      this.restoreQuestionLogic();
    }

    if (sessionStorage.getItem(LOGIC_CHAIN[2])) {
      this.callbackformComponent.setSuccessStatus();
      this.callbackformComponent.disableSendButton();
    }
  }

  private initBaseComponents() {
    this.rootComponent = new RootComponent()
    this.overlayComponent = new OverlayComponent();
    this.modalComponent = new ModalComponent(this.initParams)
    this.printingComponent = new PrintingComponent(this.initParams)
    this.textAreaComponent = new TextAreaComponent(this.initParams)
    this.headerComponent = new HeaderComponent(this.initParams)
    this.callbackformComponent = new CallbackFormComponent(this.sendData)
    this.avatarComponent = initAvatarCreator(this.params).baseCreateAvatar().render()

    this.messageListComponent = this.modalComponent.getMessageListElement()
    this.modalBoxComponent = this.modalComponent.getModalBoxElement()
    this.headerBoxComponent = this.modalComponent.getHeaderBoxElement()
  
    this.headerAvatarBoxComponent = this.headerComponent.getAvatarBoxElement()
  }

  private addComponentsHandlers() {
    this.callbackformComponent.addSendButtonClickHandler(this.sendLead.bind(this))
    this.textAreaComponent.addSendButtonClickHandler(this.generateAnswer.bind(this))
  }

  private renderBaseComponents() {
    render(document.querySelector(this.params.container_id), this.rootComponent, RenderPosition.BEFOREEND)
    render(this.rootComponent.getElement(), this.modalComponent, RenderPosition.BEFOREEND)
    !this.params.without_header && render(this.headerBoxComponent, this.headerComponent, RenderPosition.AFTERBEGIN)
    this.avatarComponent.relocationDOM(this.headerAvatarBoxComponent)
  }

  private restoreWelcomeLogic() {
    render(this.messageListComponent, new MessageDateComponent(), RenderPosition.BEFOREEND)
    render(this.messageListComponent, new MessageComponent(this.texts.firstMsg, MessageSender.CONSULTANT), RenderPosition.BEFOREEND)
    render(this.messageListComponent, new MessageComponent(this.texts.secondMsg, MessageSender.CONSULTANT), RenderPosition.BEFOREEND)
    render(this.modalBoxComponent, this.textAreaComponent, RenderPosition.BEFOREEND)
    setTimeout(() => this.textAreaComponent.show(), 0)
  }

  private restoreQuestionLogic() {
    if (!sessionStorage.getItem('answer')) {
      this.generateAnswer();

      return;
    }

    if (sessionStorage.getItem('answer') && !sessionStorage.getItem(LOGIC_CHAIN[1])) {
      const printLength = 5000;
      const readLength = 1000;
      render(this.messageListComponent, new MessageComponent(sessionStorage.getItem('question'), MessageSender.USER), RenderPosition.BEFOREEND, true)
      this.continueModalLogic(printLength, readLength);

      return;
    }

    if (sessionStorage.getItem('answer') && sessionStorage.getItem(LOGIC_CHAIN[1])) {
        render(this.messageListComponent, new MessageComponent(sessionStorage.getItem('question'), MessageSender.USER), RenderPosition.BEFOREEND)
        render(this.messageListComponent, new MessageComponent(sessionStorage.getItem('answer'), MessageSender.CONSULTANT), RenderPosition.BEFOREEND)
        render(this.messageListComponent, new MessageComponent(this.texts['msgAnsByPhone'], MessageSender.CONSULTANT), RenderPosition.BEFOREEND)
        render(this.messageListComponent, this.callbackformComponent, RenderPosition.BEFOREEND, true)
    }
  }
  
  private startModalLogic() {
    render(this.messageListComponent, this.printingComponent, RenderPosition.BEFOREEND)
    setTimeout(() => {
      this.printingComponent.removeElement()
      render(this.messageListComponent, new MessageDateComponent(), RenderPosition.BEFOREEND)
      render(this.messageListComponent, new MessageComponent(this.texts.firstMsg, MessageSender.CONSULTANT), RenderPosition.BEFOREEND)
    }, 2000)
    setTimeout(() => {
      render(this.messageListComponent, this.printingComponent, RenderPosition.BEFOREEND)
    }, 4700)
    setTimeout(() => {
      this.printingComponent.removeElement()
      render(this.messageListComponent, new MessageComponent(this.texts.secondMsg, MessageSender.CONSULTANT), RenderPosition.BEFOREEND)
    }, 6000)
    setTimeout(() => {
      render(this.modalBoxComponent, this.textAreaComponent, RenderPosition.BEFOREEND)
      setTimeout(() => this.textAreaComponent.show(), 0)
    }, 7000)

    sessionStorage.setItem(LOGIC_CHAIN[0], "1");
  }

  private continueModalLogic(printLength, readLength) {
    setTimeout(() => {
      render(this.messageListComponent, this.printingComponent, RenderPosition.BEFOREEND)
    }, readLength)
    setTimeout(() => {
      this.printingComponent.removeElement()
      render(this.messageListComponent, new MessageComponent(sessionStorage.getItem('answer'), MessageSender.CONSULTANT), RenderPosition.BEFOREEND, true)
    }, readLength + printLength + 500)
    setTimeout(() => {
      render(this.messageListComponent, this.printingComponent, RenderPosition.BEFOREEND)
    }, readLength + printLength + 6000)
    setTimeout(() => {
      this.printingComponent.removeElement()
      render(this.messageListComponent, new MessageComponent(this.texts['msgAnsByPhone'], MessageSender.CONSULTANT), RenderPosition.BEFOREEND, true)
    }, readLength + printLength + 10000)
    setTimeout(() => {
      render(this.messageListComponent, this.callbackformComponent, RenderPosition.BEFOREEND, true)
    }, readLength + printLength + 13000)

    sessionStorage.setItem(LOGIC_CHAIN[1], "1");
  }

  private async generateAnswer() {
    const question = sessionStorage.getItem('question') ? sessionStorage.getItem('question') : this.textAreaComponent.textArea.value;

    sessionStorage.setItem('question', question);
    sessionStorage.setItem('activeWidget', this.params.container_id);
    this.params.disableWidgetsCallback(this.params.container_id);

    setTimeout(() => {
      render(this.messageListComponent, new MessageComponent(question, MessageSender.USER), RenderPosition.BEFOREEND, true)
    }, 500)

    this.textAreaComponent.hide()
    setTimeout(() => this.textAreaComponent.removeElement(), 200)

    interface questionForm extends FormData {
      question: string;
      source: string;
    }

    const error =(e) => {
      this.callbackformComponent.enableSendButton();
      render(this.messageListComponent, new MessageComponent(e.message, MessageSender.CONSULTANT), RenderPosition.BEFOREEND);
    }

    try {
      const { leadId, leadTimestamp, answer } = await fetch(config.questionUrl, {
        headers: {
            "Content-Type": 'application/json',
            "Accept": "application/json,",
        },
        method: 'post',
        body: JSON.stringify({
          question,
          source: window.location.origin,
        } as questionForm)
      })
      .then((data) => data.json());

      if (!answer) {
        sessionStorage.setItem('answer', 'Простите, на данный момент не могу ответить Вам на этот вопрос. Попробуйте обратиться к нам немного позже.');

        render(this.messageListComponent, this.printingComponent, RenderPosition.BEFOREEND)
          setTimeout(() => {
            this.printingComponent.removeElement()
            render(this.messageListComponent, new MessageComponent(sessionStorage.getItem('answer'), MessageSender.CONSULTANT), RenderPosition.BEFOREEND, true);
          }, 3000);

        return;
      }

      sessionStorage.setItem('leadId', leadId);
      sessionStorage.setItem('leadTimestamp', leadTimestamp);
      sessionStorage.setItem('answer', answer);
    } catch (er) {
      error(er)
    }

    const printLength = sessionStorage.getItem('answer').length * 30 + 1000;

    this.continueModalLogic(printLength, question.length * 10)
  }

  private async sendLead() {
    this.callbackformComponent.disableSendButton();

    const error =() => {
      this.callbackformComponent.enableSendButton()
      render(this.messageListComponent, new MessageComponent(this.texts['netErr'], MessageSender.CONSULTANT), RenderPosition.BEFOREEND)
    }

    interface leadForm extends FormData {
      phone: string;
      name: string;
      timestamp: number;
    }

    const { name, phone } = this.sendData;

    try {
      const response = await fetch(`${config.leadUrl}/${sessionStorage.getItem('leadId')}`, {
        headers: {
            "Content-Type": 'application/json',
            "Accept": "application/json,",
        },
        method: 'post',
        credentials: "same-origin",
        body: JSON.stringify({
          name,
          phone: phone.replace(/\s+/g, ''),
          timestamp: +sessionStorage.getItem('leadTimestamp'),
        } as leadForm)
      });
      if (response.status >= 200 && response.status < 300) {
        this.callbackformComponent.setSuccessStatus()
        sessionStorage.setItem(LOGIC_CHAIN[2], "1");
      } else {
        error()
      }
    } catch (er) {
      error()
    }
  }

  disableCurrentWidget() {
    if (this.textAreaComponent) {
      this.textAreaComponent.hide()
      setTimeout(() => this.textAreaComponent.removeElement(), 0)
    }

    render(this.modalComponent.getModalBoxElement(), this.overlayComponent, RenderPosition.AFTEREND)
  }
}

export default PopUpProfWidget
