// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#root__pfdwt-widget--PC7AS4 .printing__print-box--2GlvZ{color:#7f7f7f;font-size:16px!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root__pfdwt-widget--PC7AS4": "root__pfdwt-widget--PC7AS4",
	"print-box": "printing__print-box--2GlvZ"
};
export default ___CSS_LOADER_EXPORT___;
