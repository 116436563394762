import AbstractComponent from '../abstract-component';
import * as styles from './root.css';

export class RootComponent extends AbstractComponent {

  constructor() {
    super()
  }

  getTemplate() {
    return `<div id="root__pfdwt-widget--PC7AS4" class="${styles['clear-all']}"></div>`
  }
}
