// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#root__pfdwt-widget--PC7AS4 .messageDate__item-box--1EbFD{align-self:center;margin-bottom:20px;max-width:350px}#root__pfdwt-widget--PC7AS4 .messageDate__item--3Z1Mj{background-color:#e1f2fb;border-radius:8px;box-shadow:0 0 3px rgb(0 0 0/20%)!important;padding:10px 15px}#root__pfdwt-widget--PC7AS4 .messageDate__item-text--1Lhop{color:#2f2e39;font-size:16px!important;letter-spacing:-.35px;line-height:22px!important;margin-bottom:0;margin-top:0;overflow-wrap:break-word;text-align:left;word-break:break-word}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root__pfdwt-widget--PC7AS4": "root__pfdwt-widget--PC7AS4",
	"item-box": "messageDate__item-box--1EbFD",
	"item": "messageDate__item--3Z1Mj",
	"item-text": "messageDate__item-text--1Lhop"
};
export default ___CSS_LOADER_EXPORT___;
