import AbstractComponent from '../abstract-component';
import * as styles from './overlay.css';

export class OverlayComponent extends AbstractComponent {

  constructor() {
    super()
  }

  getTemplate() {
    return `<div id="overlay__pfdwt-widget--PC7AS4" class="${styles['overlay']}">Консультация ведётся в другом чате</div>`
  }
}
