// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root__clear-all--333h_ *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;color:#fff;font-family:Arial,Helvetica,sans-serif!important;font-size:13px!important;font-variant:normal;font-weight:400;letter-spacing:normal;line-height:1.2;margin:0;min-width:auto;opacity:1;padding:0;position:relative;text-align:left;text-shadow:none;text-transform:none;-moz-transition:none;-webkit-transition:none;-o-transition:none;-ms-transition:none;transition:none;white-space:normal;word-spacing:0!important;z-index:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clear-all": "root__clear-all--333h_"
};
export default ___CSS_LOADER_EXPORT___;
