import { Params } from '../../widget/PopUpProfWidget.model';
import AbstractComponent from '../abstract-component';
import * as styles from './printing.css'

export class PrintingComponent extends AbstractComponent {
  name: string

  constructor(initParams: Params) {
    super()
    this.name = initParams.params.fio
  }

  getTemplate() {
    return `<div class="${styles['print-box']}">${this.name} печатает...</div>`
  }
}
