import { generateNewColor } from '../../utils';
import { Params } from '../../widget/PopUpProfWidget.model';
import AbstractComponent from '../abstract-component';
import * as styles from './textArea.css'

const PLACEHOLDER_STRING = 'Введите ваш вопрос...'

export class TextAreaComponent extends AbstractComponent {

  textArea: HTMLTextAreaElement
  sendButton: Element
  errorMessage: Element
  sendMessageContainer: Element
  params: Params
  buttonClickHandler: () => void

  constructor(initParams: Params) {
    super()

    this.params = initParams
  }

  getTemplate() {
    return `<div class="${styles['container']} text-area-widget" style="bottom:-150px">
        <div class="${styles['box']}">
          <div class="${styles['send-message']}">
            <textarea rows="3">${PLACEHOLDER_STRING}</textarea>
          </div>
          <button type="submit" class="${styles['send-button']}" style="background: ${this.params.params.widget_color}">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18px" height="18px"
            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <g id="XMLID_2_" fill="#ffffff">
                <polygon id="XMLID_4_" class="st0" points="0,0 49.6,233.9 352.1,256 49.6,278.1 0,512 512,256 	"/>
              </g>
            </svg>
          </button>
        </div>
        <div class="${styles['error-message']}"></div>
      </div>`
  }

  initSubComponents() {
    const el = super.getElement()
    this.textArea = el.getElementsByTagName('textarea')[0]
    this.sendButton = el.getElementsByClassName(`${styles['send-button']}`)[0]
    this.errorMessage = el.getElementsByClassName(`${styles['error-message']}`)[0]
    this.sendMessageContainer = el.getElementsByClassName(`${styles['send-message']}`)[0]
  }

  addEventListeners() {
    this.textArea.addEventListener('keydown', (event: KeyboardEvent) => {
      if (this.errorMessage.innerHTML) this.errorMessage.innerHTML = ''
      if (event.key === 'Enter') {
        event.preventDefault()
        if (this.validateTextAreaInput()) this.buttonClickHandler()
      }
    })

    this.textArea.addEventListener('focus', () => {
      if (this.textArea.value === PLACEHOLDER_STRING) {
        this.textArea.value = ''
      }
    })

    this.textArea.addEventListener('blur', () => {
      if (this.textArea.value.trim() === '') {
        this.textArea.value = PLACEHOLDER_STRING
      }
    })

    this.sendButton.addEventListener('click', () => {
      if (this.validateTextAreaInput()) this.buttonClickHandler()
    })
  }

  addSendButtonClickHandler(callback: () => void) {
    this.buttonClickHandler = callback
  }

  show() {
    const el = super.getElement()
    el.setAttribute('style', 'bottom: 0')
  }

  hide() {
    const el = super.getElement()
    el.setAttribute('style', 'bottom: -150px')
  }

  private validateTextAreaInput() {
    this.textArea.value = this.textArea.value.replace(/ +/g, ' ').trim()
      if (this.textArea.value.length < 15 || this.textArea.value === PLACEHOLDER_STRING) {
        this.errorMessage.innerHTML = 'Вопрос должен содержать минимум 15 символов'
        return false
      }
    return true
  }
}
