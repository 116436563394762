import AbstractComponent from '../abstract-component';
import { MessageSender} from '../../utils'
import * as styles from './message.css'

export class MessageComponent extends AbstractComponent {

  message: string
  sender: string

  constructor(message: string, sender: string) {
    super()
    this.message = message
    this.sender = sender
  }

  getTemplate() {
    return `<div class="${styles['item-box']} ${this.sender === MessageSender.CONSULTANT ? styles['item-box__consultant'] : styles['item-box__user']}">
        <div class="${styles['item']} ${this.sender === MessageSender.CONSULTANT ? styles['item__consultant'] : styles['item__user']}">
          <p class="${styles['item-text']}">${this.message}</p>
        </div>
        <div class="${styles['nipple']}"></div>
        <div class="${styles['time']}">${this.getCurrentFormatTime()}</div>
        ${this.sender === MessageSender.USER ? 
        `<div class="${styles['check-send']}">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#67ccf6" viewBox="0 0 24 24">
            <g><path d="M7.2 12.6C7.1 12.5 7 12.3 7 12.2 7 12 7.1 11.9 7.2 11.8L8 10.9C8.1 10.8 8.2 10.7 8.4 10.7 8.5 10.7 8.7 10.8 8.8 10.9L8.8 11 12 14.5C12.1 14.6 12.1 14.6 12.2 14.6 12.3 14.6 12.4 14.6 12.4 14.5L20.2 6.2C20.3 6.1 20.5 6 20.6 6 20.8 6 20.9 6.1 21 6.2L21.8 7C21.9 7.1 22 7.3 22 7.4 22 7.6 21.9 7.7 21.8 7.9L12.6 17.8C12.5 17.9 12.3 18 12.2 18 12 18 11.9 17.9 11.8 17.8L7.3 12.8 7.2 12.6ZM7.9 14L9.5 15.8 7.6 17.8C7.5 17.9 7.3 18 7.2 18 7 18 6.9 17.9 6.8 17.8L2.3 12.8 2.2 12.6C2.1 12.5 2 12.3 2 12.2 2 12 2.1 11.9 2.2 11.8L3 10.9C3.1 10.8 3.2 10.7 3.4 10.7 3.5 10.7 3.7 10.8 3.8 10.9L3.8 11 7 14.5C7.1 14.6 7.1 14.6 7.2 14.6 7.3 14.6 7.4 14.6 7.4 14.5L7.9 14ZM10 11.8L15.2 6.2C15.3 6.1 15.5 6 15.6 6 15.8 6 15.9 6.1 16 6.2L16.8 7C16.9 7.1 17 7.3 17 7.4 17 7.6 16.9 7.7 16.8 7.9L11.5 13.6 10 11.8Z"></path></g>
          </svg>
        </div>`:
        ''}
      </div>`
  }

  show() {
    const el = super.getElement()
    if (this.sender === MessageSender.CONSULTANT) el.setAttribute('style', 'margin-left:0')
  }

  private getCurrentFormatTime = function () {
    var wdt = new Date(Date.now()),
        fh = '' + wdt.getHours(),
        fm = '' + wdt.getMinutes();
    fh = fh.length < 2 ? '0' + fh : fh;
    fm = fm.length < 2 ? '0' + fm : fm;
    return fh + ':' + fm;
  }
}
